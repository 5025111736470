jQuery(document).ready(function ($) {

    // $('video, audio').prop('muted', false);
    // console.log('ready!!');


    $('.price_title').on('click', function () {
        $('.page_header').css('z-index', '0')
    });

    $('.available').on('click', function () {
        $('.nav.main_menu').css('z-index', '0')
    });

    $('.available').on('click', function () {
        $('.page_header').css('z-index', '0')
    });

    $('.close').on('click', function () {
        $('.page_header').css('z-index', '110')
    });

    $('.close').on('click', function () {
        $('nav.main_menu').css('z-index', '110')
    });

    $('.btn.btn-default').on('click', function () {
        $('nav.main_menu').css('z-index', '110')
    });

    $('.btn.btn-default').on('click', function () {
        $('.nav.main_menu').css('z-index', '110')
    });


    $('.btn.btn-default').on('click', function () {
        $('.page_header').css('z-index', '110')
    });

});